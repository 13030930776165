import React, { useEffect, useRef, useState, useMemo } from 'react';

const garden = {
  position: 'relative',
  width: '200px',
  height: '200px',
  border: '5px solid #ccc',
  borderRadius: '10px',
};

const ball = {
  position: 'absolute',
  top: '90px',
  left: '90px',
  width: '20px',
  height: '20px',
  background: 'blue',
  borderRadius: '100%',
};

const Test = () => {
  const ballRef = useRef(null);
  const gardenRef = useRef(null);

  const [statusText, setStatusText] = useState('no dice');
  const [beta, setBeta] = useState(null);
  const [gamma, setGamma] = useState(null);

  useEffect(() => {
    if (window.DeviceOrientationEvent) {
      setStatusText('it works!');
    } else {
      return;
    }

    function handleOrientation(event) {
      setBeta(event.beta);
      setGamma(event.gamma);
    }
    window.addEventListener('deviceorientation', handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  const updateCoords = (beta, gamma) => {
    let x = beta;
    let y = gamma;

    if (x > 90) {
      x = 90;
    }
    if (x < -90) {
      x = -90;
    }

    // To make computation easier we shift the range of
    // x and y to [0,180]
    x += 90;
    y += 90;

    return {
      top: `${(190 * x) / 180 - 10}px`,
      left: `${(190 * y) / 180 - 10}px`,
    };
  };

  const ballCoords = useMemo(() => updateCoords(beta, gamma), [beta, gamma]);

  return (
    <div>
      <div className="garden" ref={gardenRef} style={garden}>
        <div className="ball" ref={ballRef} style={{ ...ball, ...ballCoords }} />
      </div>
      <div className="status">{statusText}</div>
      <p>Beta: {beta}</p>
      <p>Gamma: {gamma}</p>
    </div>
  );
};

export default Test;
